/* src/pages/search.js */
import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import { Index } from "lunr"
import { getColor,getTransmissionType,getFuelType } from '../Modules/filter-data-tools'
import Layout from "../components/layout"
import { captureEvent } from 'event-service/modules/capture'
import '../scss/srp-side-style.scss'
import '../scss/srp-fonts.scss'
import '../scss/srp-normalize.scss'

import { makeStyles } from '@material-ui/core/styles'
  ;
import Slider from '@material-ui/core/Slider';
import SearchRecommended from "./SearchRecommended"
import { FinanceCalculatorProvider } from "../components/modal/financing/storage/CalculatorContext"
import InnerHTML from 'dangerously-set-html-content'

// We can access the results of the page GraphQL query via the data props
const SearchPage = ({ data, location, pageContext }) => {
  const useStyles = makeStyles({
    root: {
      width: 300,
    },
  });
  function numberWithCommas(x) {
    if(x != undefined){
      return  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      return "0"
    }
  }

  // This corrects the logic from SearchBar.js jacking up our SRP title
  useEffect(() => {
    if (typeof window !== "undefined") {
      const elements = document.querySelectorAll(".inventory-type-title");

      elements.forEach((el) => {
        if (el.textContent.includes("AstonMartin")) {
          el.textContent = el.textContent.replace("AstonMartin", "Aston Martin");
        }
      });
    }
  }, []);
  
  const [screenWidth, setScreenWidth] = useState(null);

  // Update screen width state on resize
  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setScreenWidth(window.innerWidth);
      }
    };
  
    handleResize();
  
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);
  
  const DealerMasterEvent = (typeof window !== 'undefined') ? new CustomEvent('dealer-masters-srp-load') : {};
  const localStorage = typeof window !== 'undefined' && window.localStorage
  const document = typeof window !== 'undefined' && window.document
  const history = typeof window !== 'undefined' && window.history
  const siteSettings = JSON.parse(data.siteData.siteData.siteSettings)
  const pageContentSEO = pageContext?.pageContent?.SEO?.seo;
  const pageContentSRP = pageContext?.pageContent?.SRP?.querySearchParams;
  const additionalInventory = pageContext?.altInventory !== undefined ? pageContext?.altInventory : false


  let pageSeo = {seo: pageContentSEO? pageContentSEO : siteSettings.seo.search}
  let theSlug = siteSettings.inventorymanagement.vdpslug

  let useCompactCards = siteSettings.inventorymanagement.useCompactCards

  let SRPCardBtns = siteSettings.inventorymanagement.vehiclecardbutton
  let CostRange = parseInt(siteSettings.inventorymanagement.costrange)
  let MileageRange = parseInt(siteSettings.inventorymanagement.mileagerange)

  let AutoCheckEnabled = siteSettings.inventorymanagement.AutoCheckEnabled
  
  let inventorySortArray = siteSettings.inventorymanagement.sortArray;
  let useCustomSort = siteSettings.inventorymanagement.UseCustomSort;


  let ShowLocationFilter = siteSettings.inventorymanagement.LocationFilter
  let HideNewUsed = siteSettings.inventorymanagement.HideNewUsed
  let strikeMSRP = siteSettings.inventorymanagement.strikeMSRP
  let retailPriceCustomText = siteSettings.inventorymanagement.retailPriceCustomText
  let dealerPriceCustomText = siteSettings.inventorymanagement.dealerPriceCustomText
  let retailPriceDiscountCustomText = siteSettings.inventorymanagement.retailPriceDiscountCustomText
  const sortKey = useRef(null);
  const sortValue = useRef(null);
  const sortTitle = useRef(null);
  const secondarySortKey = useRef(null);
  const secondarySortValue = useRef(null);
  const secondarySortTitle = useRef(null);
  const lead_url = data.site.siteMetadata.lead_url
  const site_id = data.site.siteMetadata.site_id

  const gtmId = siteSettings.dealerInfo.gtmId
  const buildDate = new Date(data.siteData.siteData.buildDate)

  const topHtmlSRP = siteSettings.inventorymanagement.topHtmlSRP
  const botHtmlSRP = siteSettings.inventorymanagement.botHtmlSRP
  const showMoreInventory = siteSettings.inventorymanagement.showMoreInventory || false;
  const showMoreInventoryHTML = siteSettings.inventorymanagement.showMoreInventoryHTML || '';
  const overwriteShowMoreInventory = pageContext?.pageContent?.SRP?.overwriteShowMoreInventory || false;
  const overwriteShowMoreInventoryHTML = pageContext?.pageContent?.SRP?.overwriteShowMoreInventoryHTML || '';
  const staticTopHtmlSrp =  pageContext?.pageContent?.SRP?.topHtmlStaticSRP;
  const staticBottomHtmlSRP = pageContext?.pageContent?.SRP?.botHtmlStaticSRP;

  const overwriteCTAs = pageContext?.pageContent?.SRP?.overwriteCTAs;
  const staticBtns = pageContext?.pageContent?.SRP?.SRPSettings;
  const overwriteInlineOffers = pageContext?.pageContent?.InlineOffers?.overwriteInlineOffers;
  const staticSrpInlineOffers = pageContext?.pageContent?.InlineOffers;

  const unavailableImg = siteSettings.inventorymanagement.unavailableImg
  const disclaimer = siteSettings.inventorymanagement.disclaimerSRP
  const disclaimerCalculator = siteSettings.inventorymanagement.disclaimerCalculator
  const disclaimerContact = siteSettings.inventorymanagement.disclaimerContact
  const disclaimerFinanceApp = siteSettings.inventorymanagement.disclaimerFinanceApp
  const downpayment = Number(siteSettings.inventorymanagement.downpayment)
  const rates =  [
    Number(siteSettings.inventorymanagement.aRate),
    Number(siteSettings.inventorymanagement.bRate),
    Number(siteSettings.inventorymanagement.cRate),
    Number(siteSettings.inventorymanagement.dRate),
    Number(siteSettings.inventorymanagement.eRate),
    Number(siteSettings.inventorymanagement.fRate),
  ]
  const defaultMonth = Number(siteSettings.inventorymanagement.months)
  const fallbackText = siteSettings.inventorymanagement.fallbackText 
  const usedTextHeader = siteSettings.inventorymanagement.usedTextHeader;
  const certifiedTextHeader = siteSettings.inventorymanagement.certifiedTextHeader;
  const inventoryTextHeader = siteSettings.inventorymanagement.inventoryTextHeader;
  useEffect(()=>{    
      insertUrlParamSorting(sortKey.current, sortValue.current, sortTitle.current);

  },[])

  function formatPrice(price, defaultPrice) {
    var decSpot = 0 //siteSettings.inventorymanagement.decimalSpaceHide ? 0 : 2
    let thePrice = (defaultPrice != undefined && price == 0
      ? defaultPrice
      : '$' + price.toFixed(decSpot).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    return thePrice
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }
  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }
  function removeStorageItem(item){
    typeof localStorage.removeItem !== 'undefined'
      ? localStorage.removeItem(item)
      : console.log('no localStorage')
  }
  // LunrIndex is available via page query
  const { store } =  data.AltLunrIndex
  const [StateFilters, setStateFilters] = useState(
    [{ name: "vin", value: null, label: null,results:[],display:null },
      { name: "make", value: [], label: null,results:[],display:[] },
      { name: "dealer", value: [], label: null,results:[],display:[] },
      { name: "model", value: [], label: null,results:[],display:[] },
      { name: "trim", value: [], label: null,results:[],display:[] },
      { name: "bodystyle", value: [], label: null,results:[],display:[] },
      { name: "year", value: [store.year[0], store.year[store.year.length - 1]], label: "Year",results:[],display:[] },
      { name: "certified", value: [], label: null,results:[],display:[] },
      { name: "new", value: [], label: null,results:[],display:[] },
      { name: "bodytype", value: [], label: null,results:[],display:[] },
      { name: "mileage", value: [], label: "0 - "+ numberWithCommas(store.mileage[store.mileage.length - 1]) + "+",results:[],display:[] },
      { name: "cost", value: [], label: "Price",results:[],display:[] },
      { name: "search", value: null, label: null,results:[],display:null },
      { name: "fav", value: null, label: null,results:[],display:null },
      { name: "engine", value: [], label: null,results:[],display:[] },
      { name: "citympg", value: null, label: null,results:[],display:null },
      { name: "hwympg", value: null, label: null,results:[],display:null },
      { name: "drivetrain", value: [], label: null,results:[],display:[] },
      { name: "transmission", value: [], label: null,results:[],display:[] },
      { name: "fuel", value: [], label: null,results:[],display:[] },
      { name: "interiorcolor", value: [], label: null,results:[],display:[] },
      { name: "exteriorcolor", value: [], label: null,results:[] ,display:[]},
      { name: "specials", value: [], label: null,results:[] ,display:[]},
      { name: "comment", value: [], label: null,results:[] ,display:[]},
      { name: "comments4", value: [], label: null,results:[] ,display:[]},
      { name: "daysinstock", value: [], label: null,results:[] ,display:[]},
      { name: "status", value: [], label: null,results:[] ,display:[]},
      { name: "sorting", value: null, label: null,results:[],display:null }
    ]);
  const [sliderMileageValue, setMileageValue] = useState([0, store.mileage[store.mileage.length - 1]]);
  const [sliderCostValue, setCostValue] = useState([0, store.price[store.price.length - 1]]);
  const [sliderYearValue, setYearValue] = useState([store.year[0], store.year[store.year.length - 1]]);
  const [filterItems, setfilterItems] = useState({
    Make: {},
    Comments4:{},
    DealerName:{},
    Model: {},
    BodyType: {},
    Year: {},
    Trim:{},
    CityMPG:{},
    HwyMPG:{},
    Drivetrain:{},
    EngineFuelType:{},
    InteriorColor:{},
    GenericExteriorColor:{},
    Engine:{},
    BodyStyle:{},
    Transmission:{}
  })
  const adjustedParam = location.search;
  const adjustedParamCheck = adjustedParam.includes('fq');

  const [queryState, setQueryState] = useState(pageContentSRP && !adjustedParamCheck ? pageContentSRP:location.search );
  const SearchParam = pageContentSRP? pageContentSRP:location.search 
  const params = new URLSearchParams(SearchParam.slice(1))
  const [timer, setTimer] = useState(null)
  const SearchTextBox = params.get('search')


const getParamResults = (TheIndex) =>{
  let theseResults = []
  const params = new URLSearchParams(pageContentSRP&& !adjustedParamCheck ? pageContentSRP:location.search.slice(1))
  const size = Array.from(params).length

  const FiltersToRemoveSpaces = ['dealer','make','model',"bodystyle","trim","bodytype","drivetrain","transmission","comments4"]

  const filterHandler = (elm,p) =>{
    let returnVal = p
    if(FiltersToRemoveSpaces.includes(elm)){
        returnVal = p.split(' ').join('').split('-').join('');
    }
    return returnVal
  }

  const minmaxParam = (thefilter,Results) => {
    let queryString = ''
    let min = parseInt(params.get('min-' + thefilter))
    let max = parseInt(params.get('max-' + thefilter))
    let range = thefilter === "mileage" ? MileageRange : (thefilter === "cost" ? CostRange : 1)
    setFilter(thefilter, [min,max])
    for (let i = min; i <= max; i = i + range) {
      queryString = queryString + '' + thefilter + ':' + i + ' '
    }
    params.delete('min-' + thefilter)
    params.delete('max-' + thefilter)
    if(Results.length > 0){
      Results = combineResults(Results,TheIndex.search(queryString))
    }else {
      Results = TheIndex.search(queryString)
    }
    return Results
  }
  let queryString = ''

  if(params.get('min-year') !== null) {
    theseResults =  minmaxParam("year",theseResults)
  }
  if(params.get('min-mileage') !== null) {
    theseResults =  minmaxParam("mileage",theseResults)
  }
  if(params.get('min-cost') !== null) {
    theseResults = minmaxParam("cost",theseResults)
  }
  if(params.get('min-daysinstock') !== null) {
    theseResults = minmaxParam("daysinstock",theseResults)
  }


  if (params.get('search') === null && params.get('fav') === null) {
    for (const elm of params.keys()) {
      let thisQueryString = ''
      const elementsIndex = StateFilters.findIndex(con => con.name === elm)
      params.getAll(elm).forEach((param) => {
        if(elm == 'new' && param == 'true,false'){//Available
          (!IsNew() ? setFilter('new', 'true', 'true') : console.log('IsNew'));
          (!IsUsed() ? setFilter('new', 'false', 'false') : console.log('IsUsed'));
        }else if(elm == 'new' && param == 'true' ){//NEW
          (!IsNew() ? setFilter('new', 'true', 'true') : console.log('IsNew'))
        }else if(elm == 'new' && param == 'false' ){//USED
          (!IsUsed() ? setFilter('new', 'false', 'false') : console.log('IsUsed'))
        }else if (elm == 'new' && param == 'certified' ){//Certified
          (!IsCertified() ? setFilter('certified', 'true', 'true') : console.log('IsCertified'))
        }else if(elm == 'certified' && param == 'true' ){//Certified
          (!IsCertified() ? setFilter('certified', 'true', 'true') : console.log('IsCertified'))
        }else if(elm == 'certified' && param == 'false' ){//Not Certified
          (IsCertified() ? setFilter('certified', 'false', 'false') : console.log('IsCertified'))
        }else if(elm == 'certified' && param == 'true,false' ){
            console.log('')
        }else{
          param.split(',').forEach((p) => {
            let filterValue = filterHandler(elm,p)
            if (elementsIndex !== -1) {
              if(elm == 'comment'){
                setFilter(elm, p, p)
                thisQueryString = thisQueryString + '' + elm + ':' + filterValue.split("+").join("plus") + ' '
              }
              else{
                setFilter(elm, p, p)
                thisQueryString = thisQueryString + '' + elm + ':' + filterValue.split("+").join("plus") + ' '
              }
            }
          })
        }
      });

      if(theseResults.length > 0){
        theseResults = combineResults(theseResults,TheIndex.search(thisQueryString))
      }else {
        theseResults = TheIndex.search(thisQueryString)
      }
    }

    /* * * * * * * * * * * * * * * * * * *
    *
    *   New/Used/Certified Logic Matches Findlay Template Logic
    *
    * * * * * * * * * * * * * * * * * * */
    let NUCResults = []
    let thisQuery = ''
    if((IsCertified() && IsNew() && IsUsed())){
      thisQuery = '+new:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
      thisQuery = '+new:false'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
      thisQuery = '+certified:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }else if(IsCertified() && IsNew()){
      thisQuery = '+new:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
      thisQuery = '+certified:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }else if(IsCertified() && IsUsed()){
      thisQuery = '+new:false +certified:false'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
      thisQuery = '+certified:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }else if(IsCertified()){
      thisQuery = '+certified:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }else if(IsNew() && IsUsed()){
      thisQuery = '+new:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
      thisQuery = '+new:false +certified:false'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }else if(IsNew()){
      thisQuery = '+new:true'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }else if(IsUsed()){
      thisQuery = '+new:false +certified:false'
      NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
    }

    if (theseResults.length > 0 && (IsCertified() || IsNew() || IsUsed())){
      theseResults = combineResults(theseResults, NUCResults)
    } else if (IsCertified() || IsNew() || IsUsed()){
      theseResults = NUCResults
    }
  }else if (params.get('search') !== null){
    /*search bar Code*/
    let SearchResults = []
    let SearchTypeResults = []
    let Search = false
      let searchValue = params.get("search").split(' ')
      let NewUsedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "new" || con.toString().toLowerCase() === 'pre-owned' || con.toString().toLowerCase() === 'preowned' || con.toString().toLowerCase() === 'used'});
      let CertifiedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "certified" || con.toString().toLowerCase() === "cpo" });
      searchValue = searchValue.filter(con => { return con !== "" && (con.toString().toLowerCase() !== "certified" || con.toString().toLowerCase() === "cpo") && (con.toString().toLowerCase() !== "new" || con.toString().toLowerCase() !== 'pre-owned' || con.toString().toLowerCase() !== 'preowned' || con.toString().toLowerCase() !== 'used') })
      // SearchResults = SearchResults.concat(StateIndex.search(searchValue.join('')  + '*'))

      NewUsedOptions.forEach(s => {
        let val = s.toString().toLowerCase();
        if(val === 'pre-owned' || val === 'preowned' || val === 'used') {
          SearchTypeResults = SearchTypeResults.concat(TheIndex.search('new:false'))
        }
        if(val === 'new'){
          SearchTypeResults = SearchTypeResults.concat(TheIndex.search('new:true'))
        }
      });
      CertifiedOptions.forEach(s => {
        SearchTypeResults = SearchTypeResults.concat(TheIndex.search('certified:true'))
      });
      const searchOptions = ['vin','stock','year','model','bodystyle','transmission','fuel','make','trim','comments4']
      let OptionResults = []
      searchValue.forEach(s => {
        s = s.split('-').join('').split("+").join("plus")
        searchOptions.forEach( option =>{
          OptionResults = TheIndex.search( option + ':*' + s  + '*')
          if(OptionResults.length > 0){
            if(SearchResults.length == 0){
              SearchResults = OptionResults
            }else{
              SearchResults = combineResults(SearchResults,OptionResults)
            }
          }
        })
      })

      if(SearchTypeResults.length > 0 && SearchResults.length > 0 ){
        SearchResults = combineResults(SearchTypeResults,SearchResults)
      }else if(SearchTypeResults.length > 0){
        SearchResults = SearchTypeResults
      }

      Search = true
    let hasSearch = (SearchResults.length > 0)

    if (hasSearch) {
      if (theseResults.length > 0 && hasSearch) {
        theseResults = combineResults(theseResults, SearchResults)
      } else if (hasSearch) {
        theseResults = SearchResults
      }
    }
    if(Search && !hasSearch){
      theseResults = [];
    }
  }else{
    let thisQueryString = ''
    getFavorites().forEach((fav) => {
      thisQueryString = thisQueryString + 'vin:' + fav + '* '
    });
      theseResults = TheIndex.search(thisQueryString)
  }
  if(size === 0 ){
    theseResults = TheIndex.search("")
  }
  return theseResults
};

  let allData = {nodes:[]} 
  allData.nodes = data.AllInventory
  let allDataDict = {}

  allData.nodes.forEach((item) => {
    if(item.VehicleInfo.Make == "NONE")
    {
      item.VehicleInfo.Make = " "
    }
    if(item.VehicleInfo.VehicleStatus !="_hidden") {
      allDataDict[item.VehicleInfo.VIN] = item;
    }
  })

  //console.log(allDataDict)

  function getDataByKey(key, value) {
    let data = []
    if (key === "bodytype") {
      data = Object.keys(allDataDict).filter((d) => { return allDataDict[d].VehicleInfo.BodyType == value })
    }
    let returnData = []
    data.forEach((d) => {
      returnData = returnData.concat({ ref: d })
    })
    return returnData
  }

  function getFavorites() {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  const [StateIndex, setIndex] = useState(Index.load(additionalInventory ? data.AltLunrIndex.index : data.AltLunrIndex.index));
  const [StateResults, setResults] = useState(() => ((getParamResults(StateIndex).filter(result => allDataDict[result.ref] !== undefined))));
  const [StateFavorites, setFavorites] = useState(getFavorites());
  const [StateAllResults, setStateAllResults] = useState(StateIndex.search(''))
  const [filtersApplied, setFiltersApplied] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentParams = new URLSearchParams(window.location.search) 
      const hasValidParams = Array.from(currentParams.entries()).some(([key]) => {
        return !key.includes('sort') && key !== 'min-year' && key !== 'max-year';
      });
  
      setFiltersApplied(hasValidParams);
    }
  }, [StateResults]);

  useEffect(() => {
    if (filtersApplied) {
      const refsInStateResults = new Set(StateResults.map(result => result.ref));
      const filteredResults = StateAllResults.filter(result => !refsInStateResults.has(result.ref));

      setStateAllResults(filteredResults);
    } else {
      setStateAllResults(StateIndex.search(''));
    }
  }, [filtersApplied]);

  const filterState = {new: IsNew(), used: IsUsed(), certified: IsCertified()}

  const itemsPerPage = 10;

  function setFilter(filter, value,display) {

    const elementsIndex = StateFilters.findIndex(con => con.name === filter)

    let label = null;
    let arraryIndex = -1;
    if (filter === "mileage") {
      setMileageValue(value)
      StateFilters[elementsIndex].value = value
      if (value[0] === 0 && value[1] !== store.mileage[store.mileage.length-1]) {
        StateFilters[elementsIndex].label = 'Under ' + numberWithCommas(value[1])
      }else if (value[0] !== 0 && value[1] === store.mileage[store.mileage.length-1]) {
        StateFilters[elementsIndex].label = 'Over ' + numberWithCommas(value[0])
      }
      else {
        StateFilters[elementsIndex].label = numberWithCommas(value[0]) + ' - ' + numberWithCommas(value[1])
      }
    } else if (filter === "year") {
      if(typeof value === 'object') {
        StateFilters[elementsIndex].label = value[0] + ' - ' + value[1]
      }else
      {
        StateFilters[elementsIndex].label = value
        value  = [value,value]
      }

      setYearValue(value)
      StateFilters[elementsIndex].value = value

    }else if (filter === "cost") {
      setCostValue(value)
      StateFilters[elementsIndex].value = value

      if(typeof value === 'object') {

        if (value[0] === 0 && value[1] === store.price[store.price.length-1]) {
          StateFilters[elementsIndex].label = 'Price'
        } else if (value[0] === 0 && value[1] !== store.price[store.price.length-1]) {
          StateFilters[elementsIndex].label = 'Under ' + formatPrice(value[1])
        }else if (value[0] !== 0 && value[1] === store.price[store.price.length-1]) {
          StateFilters[elementsIndex].label = 'Over ' + formatPrice(value[0])
        }
        else {
          StateFilters[elementsIndex].label = formatPrice(value[0]) + ' - ' + formatPrice(value[1]) + (value[1] == store.price[store.price.length-1] ? '+' : '')
        }
      } else{
        let value0 = ((parseInt(value) - CostRange))
        let value1 =((parseInt(value)))
        StateFilters[elementsIndex].label = formatPrice(value0) + ' - ' + formatPrice(value1)
      }

    }else if (filter === "daysinstock") {
      if(typeof value === 'object') {
        StateFilters[elementsIndex].label = value[0] + ' - ' + value[1]
      }else
      {
        StateFilters[elementsIndex].label = value
        value  = [value,value]
      }
      StateFilters[elementsIndex].value = value

    }
    else if (typeof StateFilters[elementsIndex].value === "object" && StateFilters[elementsIndex].value !== null) {

      arraryIndex = StateFilters[elementsIndex].value.indexOf(value)
      if (arraryIndex !== -1) {
        StateFilters[elementsIndex].value.splice(arraryIndex, 1);
        StateFilters[elementsIndex].display.splice(arraryIndex, 1);
      } else {
        if (value === null) {
          StateFilters[elementsIndex].value = []
          StateFilters[elementsIndex].display = []
        } else {
          StateFilters[elementsIndex].value.push(value)
          StateFilters[elementsIndex].display.push(display)
        }
      }
      if (StateFilters[elementsIndex].value.length === 0) {
        StateFilters[elementsIndex].label = null
      } else if (StateFilters[elementsIndex].value.length === 1) {
        StateFilters[elementsIndex].label = StateFilters[elementsIndex].display.length ? StateFilters[elementsIndex].display[0] :  StateFilters[elementsIndex].value[0]
      } else {
        StateFilters[elementsIndex].label = 'Multiple Types Selected'
      }
    } else {
      if (StateFilters[elementsIndex].value == value) { value = null; label = null; }

      StateFilters[elementsIndex].value = value;
      StateFilters[elementsIndex].label = label;
    }
  }

  // function getFilterLabel(filter, label) {
  //   const elementsIndex = StateFilters.findIndex(con => con.name === filter)
  //   return (StateFilters[elementsIndex].label === null ? label : StateFilters[elementsIndex].label)
  // }
  // Original function above.
  // The following is to compensate for the make displaying incorrectly in our filters after the homepage SearchBar updates.
  function getFilterLabel(filter, label) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter);
    
    let filterLabel = StateFilters[elementsIndex].label === null ? label : StateFilters[elementsIndex].label;
  
    if (filter === 'make' && filterLabel) {
      filterLabel = filterLabel.replace(/([A-Z])/g, ' $1').trim(); // Insert spaces between capital letters, e.g., "AstonMartin" -> "Aston Martin"
    }
  
    return filterLabel;
  }

  function getDistinctResults(data) {
    let makeDistinct = [];
    let finalResults = [];
    let isInDistint = false
    data.forEach(function (item) {
      isInDistint = makeDistinct.indexOf(item.ref) !== -1
      if (!isInDistint) {
        makeDistinct.push(item.ref)
        finalResults.push(item)
      }
    })
    return finalResults
  }
  function combineResults(FirstResults, SecondResults) {
    let firstArray = []
    let secondArray = []
    let results = []
    FirstResults.forEach((r) => {
      firstArray = firstArray.concat(r.ref)
    })
    SecondResults.forEach((r) => {
      secondArray = secondArray.concat(r.ref)
    })
    results = []
    firstArray.filter(element => secondArray.includes(element)).map((r) => {
      results = results.concat({ ref: r })
    });
    return results
  }

  function getFilterValue(filter, value) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter)
    return (StateFilters[elementsIndex].value === null ? value : StateFilters[elementsIndex].value)
  }

  function updateFilter(filter, value,display) {
    if (value === "null") {
      value = null
    }
    if(filter === "bodytype" && value === null){
      document.querySelectorAll('.feature__checkbox_checked')
        .forEach((item) => {
          item.classList.toggle('feature__checkbox_checked')
        })
    }
    if (filter !== null) {
      captureEvent({
        event: 'asc_element_configuration',
        page_type: 'Search Results Page',
        event_action_result: 'filter',
        event_action: 'click',
        product_name: '',
        element_state: 'selected',
        element_type: '',
        element_subtype: '',
        element_title: `${filter}`,
        element_text: `${value}`,
        element_value: `${value}`,
        element_color: '#58595b',
        element_position: '',
        element_order: ''
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      setFilter(filter, value,display);
    }

    let FinalResults = []
    let stateQuery = ''
    let search = StateFilters[StateFilters.findIndex((con) => con.name === "search")]
    let bodytype = StateFilters[StateFilters.findIndex(con => con.name === 'bodytype')]
    StateFilters.filter(con => { return con.value !== null }).forEach(function (item, index) {
      if (item.name !== "search" && item.name !== "sorting") {
        if (typeof item.value === "string") {
          //getResultsFromIndex(item.name,item.value.toLowerCase())
          stateQuery = stateQuery + '+' + item.name + ':' + item.value.split(' ').join(' +') + ' '
        } else if (typeof item.value === "object" && item.value !== null) {
          //CodeBelow for object items below only
        }
        else {
          stateQuery = stateQuery + '+' + item.name + ':' + item.value + ' '
        }
      }
    });


    let StateFilterResults = { }
    FinalResults = []
    let EmptyResultSet = false
    StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 && con.name !== 'new' && con.name !== 'certified'}).forEach(function (item, index) {
      let TheseResults = []
      let thisQuery = ''
        if(item.name === "year"){
          let start = item.value[0]
          let finish = item.value[1]
          for (let i = start; i <= finish; i++ ) {
            thisQuery = stateQuery + '+' + item.name + ':' + i
            TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
          }
        }else if(item.name === "mileage"){
            let start =  item.value[0]
            let finish =  item.value[1]

            if( item.value[0] > 0 ){
              start =  item.value[0] + MileageRange
            }

            for (let i = start; i <= finish; i = i + MileageRange ) {
              thisQuery = stateQuery + '+' + item.name + ':' + i
              TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
            }
        }else if (item.name === "cost"){
              let start =   item.value[0]
              let finish =  item.value[1]

              if( item.value[0] > 0 ){
                start =  item.value[0] + CostRange
              }
              for (let i = start; i <= finish; i = i + CostRange ) {
                thisQuery = stateQuery + '+' + item.name + ':' + i
                TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
              }
        }else if (item.name === "daysinstock"){
          let start =   item.value[0]
          let finish =  item.value[1]

          if( item.value[0] > 0 ){
            start =  item.value[0] + 1
          }
          for (let i = start; i <= finish; i = i + 1 ) {
            thisQuery = stateQuery + '+' + item.name + ':' + i
            TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
          }
        }
        else if (item.name === "comment") {
          thisQuery = stateQuery + '+' + item.name + ':*' + item.value.split("+").join("plus") + '*'
          TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
        }
        else if (typeof item.value === "object" && item.value !== null) {
            item.value.forEach((i) => {
             thisQuery = stateQuery + '+' + item.name + ':' + i.split(' ').join(' +').split('-').join('').split("+").join("plus")
             TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
           })
        }

      StateFilters[StateFilters.findIndex(con => con.name === item.name)].results = TheseResults
      StateFilterResults[item.name] = TheseResults

      EmptyResultSet = (!EmptyResultSet ? (TheseResults.length === 0) : EmptyResultSet)
      if (FinalResults.length > 0 ){
        FinalResults = combineResults(FinalResults, TheseResults)
        EmptyResultSet = (!EmptyResultSet ? (FinalResults.length === 0) : EmptyResultSet)
      } else {
        FinalResults = TheseResults
      }
    });

    /* * * * * * * * * * * * * * * * * * *
      *
      *   New/Used/Certified Logic Matches Findlay Template Logic
      *
      * * * * * * * * * * * * * * * * * * */
    let NUCResults = []
    let thisQuery = ''
    if((IsCertified() && IsNew() && IsUsed())){
      thisQuery = stateQuery + '+new:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
      thisQuery = stateQuery + '+new:false'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
      thisQuery = stateQuery + '+certified:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsCertified() && IsNew()){
      thisQuery = stateQuery + '+new:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
      thisQuery = stateQuery + '+certified:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsCertified() && IsUsed()){
      thisQuery = stateQuery + '+new:false'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
      thisQuery = stateQuery + '+certified:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsCertified()){
      thisQuery = stateQuery + '+certified:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsNew() && IsUsed()){
      thisQuery = stateQuery + '+new:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
      thisQuery = stateQuery + '+new:false +certified:false'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsNew()){
      thisQuery = stateQuery + '+new:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsUsed()){
      thisQuery = stateQuery + '+new:false +certified:false'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }


    if(IsNew() || IsUsed()){
      StateFilters[StateFilters.findIndex(con => con.name === 'new')].results = NUCResults
      StateFilterResults['new'] = NUCResults
    }
    if(IsCertified()){
      StateFilters[StateFilters.findIndex(con => con.name === 'certified')].results = NUCResults
      StateFilterResults['certified'] = NUCResults
    }
    if (FinalResults.length > 0 && (IsCertified() || IsNew() || IsUsed())){
      FinalResults = combineResults(FinalResults, NUCResults)
      EmptyResultSet = (!EmptyResultSet ? (FinalResults.length === 0) : EmptyResultSet)
    } else if (IsCertified() || IsNew() || IsUsed()){
      FinalResults = NUCResults
    }
    /*MultipleType Code*/

    /*search bar Code*/
    let SearchResults = []
    let SearchTypeResults = []
    let Search = false
    if (search.value !== null && search.value !== '') {
      let searchValue = search.value.split(' ')
      let NewUsedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "new" || con.toString().toLowerCase() === 'pre-owned' || con.toString().toLowerCase() === 'preowned' || con.toString().toLowerCase() === 'used'});
      let CertifiedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "certified" || con.toString().toLowerCase() === "cpo" });
      searchValue = searchValue.filter(con => { return con !== "" && (con.toString().toLowerCase() !== "certified" || con.toString().toLowerCase() === "cpo") && (con.toString().toLowerCase() !== "new" || con.toString().toLowerCase() !== 'pre-owned' || con.toString().toLowerCase() !== 'preowned' || con.toString().toLowerCase() !== 'used') })
     // SearchResults = SearchResults.concat(StateIndex.search(searchValue.join('')  + '*'))

      NewUsedOptions.forEach(s => {
        let val = s.toString().toLowerCase();
        if(val === 'pre-owned' || val === 'preowned' || val === 'used') {
          SearchTypeResults = SearchTypeResults.concat(StateIndex.search('new:false'))
        }
        if(val === 'new'){
          SearchTypeResults = SearchTypeResults.concat(StateIndex.search('new:true'))
        }
      });
      CertifiedOptions.forEach(s => {
        SearchTypeResults = SearchTypeResults.concat(StateIndex.search('certified:true'))
      });
      const searchOptions = ['vin','stock','year','model','bodystyle','transmission','fuel','make','trim','comments4']
      let OptionResults = []
      searchValue.forEach(s => {
        s = s.split('-').join('').split("+").join("plus")
        let innerSearchResults = []
        searchOptions.forEach( option =>{
          OptionResults = StateIndex.search( option + ':*' + s  + '*')
          if(OptionResults.length > 0){
            innerSearchResults = innerSearchResults.concat(OptionResults)
          }
        })
        if(SearchResults.length == 0){
          SearchResults = innerSearchResults
        }else{
          SearchResults = combineResults(SearchResults,innerSearchResults)
        }
      })

      if(SearchTypeResults.length > 0 && SearchResults.length > 0 ){
        SearchResults = combineResults(SearchTypeResults,SearchResults)
      }else if(SearchTypeResults.length > 0){
        SearchResults = SearchTypeResults
      }


      Search = true
    }

    let hasSearch = (SearchResults.length > 0)

    if (hasSearch) {
      if (FinalResults.length > 0 && hasSearch) {
        FinalResults = combineResults(FinalResults, SearchResults)
      } else if (hasSearch) {
        FinalResults = SearchResults
      }
    }
    if(Search && !hasSearch){
      FinalResults = [];
    }

    if(EmptyResultSet){
      FinalResults = [];
    }else{
      if(FinalResults.length == 0 ){
       //uncomment to make all results to return if no results return
        // commenting out for NIS-574
        // FinalResults = FinalResults.concat(StateIndex.search(""))
      }
    }

    setupFilterDataResults(FinalResults,false)
    setSearchParams()
    setResults(getDistinctResults(FinalResults))


  }

  function setFavoriteItems(vin) {
    return (StateFavorites.findIndex((v) => (v == vin)) === -1 ? 'like-btn like-btn-' + vin : 'like-btn  like-btn-' + vin + ' like-btn_active')
  }

  function SearchValueCheck(NewSearchValue){
    let Search = StateFilters[StateFilters.findIndex((con) => con.name === "search")]
    return Search.value == NewSearchValue
  }
  function IsCertified(){
    let IsCertifiedNewFilter = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    let IsCertified = StateFilters[StateFilters.findIndex((con) => con.name === "certified")]
    return IsCertifiedNewFilter.value.includes("certified") || IsCertified.value.includes("true")
  }
  function IsTransit(){
    let IsTransit = StateFilters[StateFilters.findIndex((con) => con.name === "status")]
    return IsTransit.value.includes("Transit")
  }
  function IsStock(){
    let IsStock = StateFilters[StateFilters.findIndex((con) => con.name === "status")]
    return IsStock.value.includes("Stock")
  }
  function IsNew(){
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    return IsNew.value.includes("true")
  }
  function IsUsed(){
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    return IsNew.value.includes("false")
  }
  function searchParamsHasNew(){
    const params = new URLSearchParams(queryState)
    const paramNew = params.get('new')
    const isNew = paramNew == true || paramNew == 'true'
    return isNew
  }
  function getHeaderLabel(){

    let header =  ""
    let IsNewVar = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    let Make = StateFilters[StateFilters.findIndex((con) => con.name === "make")]

    header = header + (IsNew() ? "New " : "")
    header = header + (IsNew() && (IsUsed() || IsCertified()) ? "&" :"")
    header = header + (IsCertified()  && !IsUsed() ? " " + certifiedTextHeader + " " : "")
    header = header + (IsUsed() ? " " + usedTextHeader + " " : "")
    // header = header + (IsNewVar.value.length === 0 &&  !IsCertified() ? "New &" + " " + usedTextHeader + " " : "")
    header = header + (IsNewVar.value.length === 0 &&  !IsCertified() ? "Available" + " " : "")

    let formatMakeLabel = (makeValue) => {
      return makeValue.replace(/([A-Z])/g, ' $1').trim();
    };
  
    let MakeLabel = Make.label && formatMakeLabel(Make.label.charAt(0).toUpperCase() + Make.label.slice(1));
    header = header + (Make.value.length == 1 ? MakeLabel + " " : "");

    return header
  }







  const renderSortOptions = () => {

      let defaultSortArray =  [{sortText: "Price - Lowest First", sortType: "sortPrice:Lowest"},
                            {sortText: "Price - Highest First", sortType: "sortPrice:Highest"},
                            {sortText: "Year - Newest First", sortType: "sortYear:Newest"},
                            {sortText: "Year - Oldest First", sortType: "sortYear:Oldest"},
                            {sortText: "Days In Stock - Newest First", sortType: "inStock:Newest"},
                            {sortText: "Days In Stock - Oldest First", sortType: "inStock:Oldest"},
                            {sortText: "Mileage - Least First", sortType: "sortMileage:Least"},
                            {sortText: "Mileage - Most First", sortType: "sortMileage:Most"},
                            {sortText: "In Stock - In Transit", sortType: "sortStock:Stock"},
                            {sortText: "In Transit - In Stock", sortType: "sortStock:Transit"}]


      let checkSortOptions = useCustomSort ? inventorySortArray : defaultSortArray;


      if (inventorySortArray.length == 0 && useCustomSort === true) {
        return <ul></ul>
        
      }

      else { 
        return  (

        <ul className="dropdown-sorting"  onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
          <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
            <div className="sr-dropdown__inner">
              <p className="sr-dropdown__name">{sortTitle.current ?? "Sort By"}</p>
            </div>
            {isOpen[6] &&
            
              <ul className="sub-dropdown-v1 search-dropdown">
          
                {checkSortOptions.map((option) => { 
                  if (option.sortType == "") {option.sortType="sortPrice:Lowest"}
                  var sortType = option.sortType.split(':') ;
                  var sort = sortType[0];
                  var sortDirection = sortType[1];
                  var sortText = option.sortText;

                  if (sortText == '') {
                    sortText = sortDirection + ' ' + sort.replace('sort', '');
                  }

                    return (
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); insertUrlParamSorting(sort, sortDirection, sortText); }}>{sortText}</li>
                      )
                  })

                  }
                
              </ul>
            
            }
          </li>
    </ul>

     )
    
        }

  }
 




  

  function favoriteItem(vin) {
    document.querySelector('.like-btn-' + vin).classList.toggle('like-btn_active')
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem", JSON.stringify(theFavs));
    setFavorites(theFavs);
  }

  function insertUrlParamSorting(key, value, title) {
    const params = new URLSearchParams(location.search)

    const size = Array.from(params).length

    const keys = [];
    if(key !== null){

      const deleteArray = ['sortPrice', 'sortYear', 'sortMileage', 'sortStock', 'inStock','SortPrice', 'SortYear', 'SortMileage', 'SortStock', 'InStock', 'sortDealer', 'SortDealer']

      for(var i = 0; i < deleteArray.length; i++){
        const url = window.location.href
        var r = new URL(url)
        const beforeURL = r.href
        r.searchParams.delete(deleteArray[i])
        const newUrl = r.href
        insertUrlParam(key, value);
        setSortingItems(key, value, title);

        if(beforeURL !=newUrl ){
          history.replaceState({ path: newUrl }, '', newUrl)
        }
      }

    }else{

      for (const elm of params.keys()) {

        params.getAll(elm).forEach((param) => {
          if(elm == 'inStock' || elm == 'InStock' ){
            if(param == 'Newest'){

              keys.push({key:elm, value:param, title: "Days In Stock - Newest First"});

            }else if(param == 'Oldest'){

              keys.push({key:elm, value:param, title: "Days In Stock - Oldest First"});
            }
          }
          if(elm == 'sortPrice' || elm == 'SortPrice'  ){
            if(param == 'Highest'){

              keys.push({key:elm, value:param, title: "Price - Highest First"});

            }else if(param == 'Lowest'){

              keys.push({key:elm, value:param, title: "Price - Lowest First"});
            }
          }
          if(elm == 'sortYear' || elm == 'SortYear' ){
            if(param == 'Newest'){

              keys.push({key:elm, value:param, title: "Year - Newest First"});

            }else if(param == 'Oldest'){

              keys.push({key:elm, value:param, title: "Year - Oldest First"});
            }
          }
          if(elm == 'sortMileage' || elm == 'SortMileage'){
            if(param == 'Least'){

              keys.push({key:elm, value:param, title: "Mileage - Least First"});

            }else if(param == 'Most'){

              keys.push({key:elm, value:param, title: "Mileage - Most First"});
            }
          }
          if(elm == 'sortStock' || elm == 'SortStock' ){
            if(param == 'Stock'){

              keys.push({key:elm, value:param, title: "In Stock - In Transit"});

            }else if(param == 'Transit'){

              keys.push({key:elm, value:param, title: "In Transit - In Stock"});
            }
          }
          //ADD LOCATION SORT HERE DEALER SORT __________________________________________________
          if(elm == 'sortDealer' || elm == 'SortDealer' ){

            keys.push({key:elm, value:param, title: "Sort By Dealership"});

          }
        });

      }

      const keyPos = keys.length;
      if(keyPos > 0 ){
        insertUrlParam(keys[keyPos - 1].key, keys[keyPos - 1].value)
        setSortingItems(keys[keyPos - 1].key, keys[keyPos - 1].value, keys[keyPos - 1].title);
      }
      if(keyPos > 1 ){
        console.log('THERE ARE TWO PARAMS')
        insertUrlParam(keys[1].key, keys[1].value);
        sortKey.current = keys[0].key;
        sortValue.current = keys[0].value;
        sortTitle.current = keys[0].title;
        secondarySortKey.current = keys[1].key
        secondarySortValue.current = keys[1].value
        secondarySortTitle.current = keys[1].title
      }
    }
  }

  const returnSortParams = (key, value, title) =>{
    sortKey.current = key;
    sortValue.current = value;
    sortTitle.current = title;
    return {key, value, title}
  }

  function setSortingItems(key, value, title) {
    returnSortParams(key, value, title)
    setResults(sortingItems(key, value, title))
    updateFilter(null, null)
  }

  function convertStatus(status){
    return ((status == 'In-Transit') ? 1 : 0)
  }

  function sortTheItems(res) {

    let key = sortKey.current
    let value = sortValue.current
    let title = sortTitle.current

    //SORT BY PRICE
    if(key === 'sortPrice'  || key === 'SortPrice'  ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]
        let firstSortA = (itemA.VehicleInfo.IsNew == true) && itemA.Pricing.ExtraPrice1 <= 0 ? itemA.Pricing.List : itemA.Pricing.ExtraPrice1
        let firstSortB = (itemB.VehicleInfo.IsNew == true) && itemB.Pricing.ExtraPrice1 <= 0 ? itemB.Pricing.List  : itemB.Pricing.ExtraPrice1
          if (value == 'Lowest') {
            return  firstSortA - firstSortB;
          } else if (value == 'Highest') {
            return firstSortB - firstSortA;
          } else {
            return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
          }

      })
    }

    //SORT BY YEAR
    if(key === 'sortYear' || key === 'SortYear'  ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]
          if (value == 'Newest') {
            return itemB.VehicleInfo.Year - itemA.VehicleInfo.Year
          } else if (value == 'Oldest') {
            return  itemA.VehicleInfo.Year - itemB.VehicleInfo.Year
          } else {
            return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
          }

      })

    }
    //SORT BY MILES
    if(key === 'sortMileage' || key === 'SortMileage' ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]
          if (value == 'Least') {
            return  itemA.VehicleInfo.Mileage - itemB.VehicleInfo.Mileage
          } else if (value == 'Most') {
            return  itemB.VehicleInfo.Mileage - itemA.VehicleInfo.Mileage
          } else {
            return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
          }

      })
    }
    //SORT BY DAYS IN STOCK
    if(key === 'inStock' || key === 'InStock'){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]

        let itemADate = new Date(itemA.VehicleInfo.InStockDate);
        let itemBDate = new Date(itemB.VehicleInfo.InStockDate);
          if (value == 'Newest') {
            return  itemADate - itemBDate
          } else if (value == 'Oldest') {
            return  itemBDate - itemADate
          } else {
            return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
          }

      })
    }
    //SORT STOCK VS TRANSIT
    if(key === 'sortStock' || key === 'SortStock'){

      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]

        let itemConvertedA = convertStatus(itemA.VehicleInfo.VehicleStatus);
        let itemConvertedB = convertStatus(itemB.VehicleInfo.VehicleStatus);

        if (value == 'Stock') {

          return  itemConvertedA - itemConvertedB;


        }else if(value == 'Transit') {
          return itemConvertedB - itemConvertedA;

        }
      })
    }else{

      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]
        return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
      })
    }

    //SORT BY DEALER -- DEALER NAME FROM VALUE PARAM DECIDES PRIMARY --- SORT PRIMARY FIRST THEN SECONDARY THEN IN TRANSIT VEHICLES
    if(key == 'sortDealer' || key == 'SortDealer'){

      //Dealer Location SPLITTER
      var primaryDealerObjs = [];
      var secondaryDealerObjs = [];

      for(var i = 0; i < res.length; i++){
        let sortDealerName = allDataDict[res[i].ref].VehicleInfo.DealerName
        let dealerNameNoSpace = sortDealerName.replace(/\s+/g, '');
        if( dealerNameNoSpace === value ){
          primaryDealerObjs.push(res[i]);

        }else{
          secondaryDealerObjs.push(res[i]);
        }

      }

      res = primaryDealerObjs.concat(secondaryDealerObjs)

    }

    if(secondarySortKey.current !== null || secondarySortKey.current !== undefined){
      let secondaryKey = secondarySortKey.current
      let secondaryVal = secondarySortValue.current
      let secondaryTitle = secondarySortTitle.current
      let keys = [{key, value, title}, {secondaryKey, secondaryVal, secondaryTitle}];

      let key1 = keys[0].key;
      let key2 = keys[1].secondaryKey;
      let val1 = keys[0].value;
      let val2 = keys[1].secondaryVal;

      res.sort((a, b) => {

        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]


        let firstSortA = '';
        let firstSortB = '';

        let secondtSortA = '';
        let secondtSortB = '';

        let finalSortPrimary = '';
        let finalSortSecondary = '';

        if(key1){
          if(key1 == 'sortPrice' || key1 == 'SortPrice'){
            firstSortA = (itemA.VehicleInfo.IsNew == true) && itemA.Pricing.ExtraPrice1 <= 0 ? itemA.Pricing.List : itemA.Pricing.ExtraPrice1
            firstSortB = (itemB.VehicleInfo.IsNew == true) && itemB.Pricing.ExtraPrice1 <= 0 ? itemB.Pricing.List  : itemB.Pricing.ExtraPrice1
            if(val1 == 'Lowest'){
              firstSortA = (firstSortA == 0) ? 10000000 : firstSortA
              firstSortB = (firstSortB == 0) ? 10000000 : firstSortB
            }
          }
          if(key1 == 'sortYear' || key1 == 'SortYear'){
            firstSortA = itemA.VehicleInfo.Year
            firstSortB = itemB.VehicleInfo.Year
          }
          if(key1 == 'sortMileage' || key1 == 'SortMileage'){
            firstSortA = itemA.VehicleInfo.Mileage
            firstSortB = itemB.VehicleInfo.Mileage
          }
          if(key1 == 'inStock' || key1 == 'InStock'){
            let itemADate = new Date(itemA.VehicleInfo.InStockDate);
            let itemBDate = new Date(itemB.VehicleInfo.InStockDate);
            firstSortA = itemADate
            firstSortB = itemBDate
          }
          if(key1 == 'sortStock' || key1 == 'SortStock'){
            let itemConvertedA =  convertStatus(itemA.VehicleInfo.VehicleStatus);
            let itemConvertedB =  convertStatus(itemB.VehicleInfo.VehicleStatus);

            firstSortA = itemConvertedA
            firstSortB = itemConvertedB
          }
          if(key1 == 'sortDealer' || key1 == 'SortDealer'){
            firstSortA = itemA.VehicleInfo.DealerID
            firstSortB = itemB.VehicleInfo.DealerID
          }
        }



        if(key2){
          if(key2 == 'sortPrice' || key2 == 'SortPrice'){
            secondtSortA = (itemA.VehicleInfo.IsNew == true) && itemA.Pricing.ExtraPrice1 <= 0 ? itemA.Pricing.List : itemA.Pricing.ExtraPrice1
            secondtSortB = (itemB.VehicleInfo.IsNew == true) && itemB.Pricing.ExtraPrice1 <= 0 ? itemB.Pricing.List  : itemB.Pricing.ExtraPrice1
            if(val2 == 'Lowest'){
              secondtSortA = (secondtSortA == 0) ? 10000000 : secondtSortA
              secondtSortB = (secondtSortB == 0) ? 10000000 : secondtSortB
            }
          }
          if(key2 == 'sortYear' || key2 == 'SortYear'){
            secondtSortA = itemA.VehicleInfo.Year
            secondtSortB = itemB.VehicleInfo.Year
          }
          if(key2 == 'sortMileage' || key2 == 'SortMileage'){
            secondtSortA = itemA.VehicleInfo.Mileage
            secondtSortB = itemB.VehicleInfo.Mileage
          }
          if(key2 == 'inStock' || key2 == 'InStock'){
            let itemADate = new Date(itemA.VehicleInfo.InStockDate);
            let itemBDate = new Date(itemB.VehicleInfo.InStockDate);
            secondtSortA = itemADate
            secondtSortB = itemBDate
          }
          if(key2 == 'sortStock' || key2 == 'SortStock'){
            let itemConvertedA =  convertStatus(itemA.VehicleInfo.VehicleStatus);
            let itemConvertedB =  convertStatus(itemB.VehicleInfo.VehicleStatus);

            secondtSortA = itemConvertedA
            secondtSortB = itemConvertedB
          }
          if(key2 == 'sortDealer' || key2 == 'SortDealer'){

            secondtSortA = itemA.VehicleInfo.DealerID
            secondtSortB = itemB.VehicleInfo.DealerID
          }
        }


        if(val1 == 'Newest' || val1 ==  'Highest' || val1 ==  "Most" || val1 ==  "Transit" || val1 == "Desc"){
          finalSortPrimary = firstSortB - firstSortA

        }else{
          finalSortPrimary = firstSortA - firstSortB

        }

        if(val2 === 'Newest' || val2 === 'Highest' || val2 === "Most" || val2 === "Transit" || val2 == "Desc"){
          finalSortSecondary = secondtSortB - secondtSortA;


        }else{

          finalSortSecondary = secondtSortA - secondtSortB;

        }


        return finalSortPrimary || finalSortSecondary

      });


      try {
        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref];
          let itemB = allDataDict[b.ref];

          // Check if PhotoCount is zero for either item
          if (itemA.VehicleInfo.PhotoCount === 0 && itemB.VehicleInfo.PhotoCount === 0) {
            // If both have PhotoCount as 0, maintain their original order
            return 0;
          } else if (itemA.VehicleInfo.PhotoCount === 0) {
            // If itemA has PhotoCount as 0, move it down
            return 1;
          } else if (itemB.VehicleInfo.PhotoCount === 0) {
            // If itemB has PhotoCount as 0, move it up
            return -1;
          } else {
            return 0;
          }
        });
      } catch (e) {
        // Handle errors here if necessary
      }

      return res; ////RETURN SECONDARY SORTING

    }

    return res;
  }

  function sortingItems(key, value, title) {
    let res = StateResults

    if(key === 'sortDealer'){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]
        if (value == 'Lowest') {
          return itemA.VehicleInfo.DealerName - itemB.VehicleInfo.DealerName
        } else {
          return itemB.VehicleInfo.DealerName - itemA.VehicleInfo.DealerName
        }

      })
    }

    if(key === 'sortPrice'  ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]

        if (value == 'Lowest') {
          return itemA.Pricing.ExtraPrice1 - itemB.Pricing.ExtraPrice1
        } else {
          return itemB.Pricing.ExtraPrice1 - itemA.Pricing.ExtraPrice1
        }

      })
    }

    if(key == 'sortYear' ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]


        if (value == 'Newest') {
          return  itemB.VehicleInfo.Year - itemA.VehicleInfo.Year

        } else {
          return  itemA.VehicleInfo.Year - itemB.VehicleInfo.Year
        }

      })

    }
    if(key == 'sortMileage'){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]


        if (value == 'Least') {
          return  itemA.VehicleInfo.Mileage - itemB.VehicleInfo.Mileage

        } else {
          return  itemB.VehicleInfo.Mileage - itemA.VehicleInfo.Mileage
        }

      })
    }

    if(key == 'inStock' ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]

        let itemADate = new Date(itemA.VehicleInfo.InStockDate);
        let itemBDate = new Date(itemB.VehicleInfo.InStockDate);

        if (value == 'Newest') {
          return itemADate - itemBDate
        } else {
          return itemBDate - itemADate
        }

      })
    }

    if(key == 'inStock' ){
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]

        let itemADate = new Date(itemA.VehicleInfo.InStockDate);
        let itemBDate = new Date(itemB.VehicleInfo.InStockDate);

        if (value == 'Newest') {
          return itemADate - itemBDate
        } else {
          return itemBDate - itemADate
        }

      })
    }
    if(key == 'sortStock'){
      res = res.sort(function(a, b) {

        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]

        let itemConvertedA = convertStatus(itemA.VehicleInfo.VehicleStatus);
        let itemConvertedB = convertStatus(itemB.VehicleInfo.VehicleStatus);

        if (value == 'Stock') {

          return itemConvertedA - itemConvertedB
        } else {
          return itemConvertedB - itemConvertedA
        }

      })
    }else{

      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref]
        let itemB = allDataDict[b.ref]
        return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
      })
    }

    try {
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref];
        let itemB = allDataDict[b.ref];

        // Check if PhotoCount is zero for either item
        if (itemA.VehicleInfo.PhotoCount === 0 && itemB.VehicleInfo.PhotoCount === 0) {
          // If both have PhotoCount as 0, maintain their original order
          return 0;
        } else if (itemA.VehicleInfo.PhotoCount === 0) {
          // If itemA has PhotoCount as 0, move it down
          return 1;
        } else if (itemB.VehicleInfo.PhotoCount === 0) {
          // If itemB has PhotoCount as 0, move it up
          return -1;
        } else {
          return 0;
        }
      });
    } catch (e) {
      // Handle errors here if necessary
    }

    return res

  }


  function getSlideValue(newValue,range){
    let slide = [];
    slide = slide.concat(Math.round(parseInt(newValue[0]) / range) * range);
    slide = slide.concat(Math.round(parseInt(newValue[1]) / range) * range);
    return slide;
  }
  function mileageChange(event, newValue) {
    setMileageValue(newValue)
    setFilter('mileage', getSlideValue(newValue,MileageRange));
  }
  function mileageCommitted(event, newValue) {
    sliderChange(event, newValue, 'mileage', setMileageValue,MileageRange)
    setMileageValue(newValue)
  }
  function costChange(event, newValue) {
    setCostValue(newValue)
    setFilter('cost', getSlideValue(newValue,CostRange));
  }
  function costCommitted(event, newValue) {
    sliderChange(event, newValue, 'cost', setCostValue,CostRange)
    setCostValue(newValue)
  }
  function yearChange(event, newValue) {
    setYearValue(newValue)
    setFilter('year', getSlideValue(newValue,1));
  }
  function yearCommitted(event, newValue) {
    sliderChange(event, newValue, 'year', setYearValue,1)
    setYearValue(newValue)
  }


  function sliderChange(event, newValue, filter, setValue,range) {
    let slide = [];
    slide = slide.concat(Math.round(parseInt(newValue[0]) / range) * range);
    slide = slide.concat(Math.round(parseInt(newValue[1]) / range) * range);
    setValue(slide);
    updateFilter(filter, slide);
  }

  function setupFilterDataResults(theResults,IsStateResults){
    let theFilters = []
    if(IsStateResults){
      theFilters = StateFilters.filter(con => {
        return (con.value === null
                || (typeof con.value === "object"  && con.value.length === 0 )
                || (con.name === "year" && con.label === "Year")
                ||(con.name === "cost" && con.label === "Price")
                ||(con.name === "mileage" && con.label === "0 - 150,000+")) && con.results.length === 0
      })
    }else {
      theFilters = StateFilters.filter(con => {
        return (con.value === null || (typeof con.value === "object"  && con.value.length === 0 ))
      })
    }
    theFilters.forEach(function(item, index) {
      StateFilters[StateFilters.findIndex(con => con.name === item.name)].results = theResults
    });

  }

  function setupFilterData() {
    let filterDataCounts = []
    let filterData = Object.keys(allDataDict).map((key) => allDataDict[key]);

    StateResults.map((result, index) => {
      filterDataCounts.push(allDataDict[result.ref])
    })

    const getMyResults  = (allFilters,filter) => {
      let ReturnResults = StateFilters[StateFilters.findIndex(con => con.name === filter)].results
      let EmptyResultSet = false
      if(allFilters){
        ReturnResults = []
        let theFilters = StateFilters.filter(con => { return con.value !== null &&
          con.value.length > 0 &&
          con.name !== filter})
          theFilters.forEach(function (item, index) {
            EmptyResultSet = (!EmptyResultSet ? (item.results.length === 0) : EmptyResultSet)
            if(ReturnResults.length > 0){
              ReturnResults = combineResults(ReturnResults,item.results)
              EmptyResultSet = (!EmptyResultSet ? (ReturnResults.length === 0) : EmptyResultSet)
            }else{
              ReturnResults = item.results
            }
          });
      }
      if(EmptyResultSet){
        ReturnResults = []
      }
      return ReturnResults
    }

    const setFilterItem = (filterItem,key,filter,theseResults,filterFunction) => {
      if(filterFunction === undefined){
        filterFunction = (item) => { return item }
      }
      let returnItem = {show:true, count:"0" ,selected:false}
     let thisFilterDataCounts = []
      let thisStateResultsDataCounts = []
      theseResults.map((result, index) => {
        if(allDataDict[result.ref] !== undefined)
        thisFilterDataCounts.push(allDataDict[result.ref])
      })
      StateResults.map((result, index) => {
        if(allDataDict[result.ref] !== undefined)
        thisStateResultsDataCounts.push(allDataDict[result.ref])
      })
      let DataCounts = thisFilterDataCounts.filter(item => filterFunction(item.VehicleInfo[filterItem]) == key).length
      let StateDataCounts = thisStateResultsDataCounts.filter(item => filterFunction(item.VehicleInfo[filterItem]) == key).length
      let FilterValue =  StateFilters[StateFilters.findIndex(con => con.name === filter)].value

      let thisShow = (FilterValue !== null ? FilterValue.includes(key.toString().split(" ").join("")) : false)
      returnItem.selected = thisShow
      thisShow = thisShow || (Math.max(StateDataCounts,DataCounts) > 0)
      returnItem.show = thisShow
      returnItem.count = StateDataCounts === 0 ? "" : "(" + StateDataCounts + ")" //DataCounts //Math.min(StateDataCounts,DataCounts)

      return returnItem
    }
    let DealerResults =  getMyResults(true,"dealer")
    filterData.map(result => result.VehicleInfo.DealerName).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.DealerName[theKey] = setFilterItem("DealerName",theKey,"dealer",DealerResults)
    });
    let MakeResults =  getMyResults(true,"make")
    filterData.map(result => result.VehicleInfo.Make).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Make[theKey] = setFilterItem("Make",theKey,"make",MakeResults)
    });

    // let ModelResults =  getMyResults(true,"model")
    // filterData.map(result => result.VehicleInfo.Model).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
    //   let theKey = key
    //   filterItems.Model[theKey] = setFilterItem("Model",theKey,"model",ModelResults)
    // });

    let ModelResults = getMyResults(true, "model");

    let modelCount = {}; 
    
    filterData.forEach(result => {
        let model = result.VehicleInfo.Model;
        if (model && model.trim() !== "") {
            const normalizedModel = model.toLowerCase();
            if (modelCount[normalizedModel]) {
                modelCount[normalizedModel].count++;
                modelCount[normalizedModel].current.push(model)
            } else {
                modelCount[normalizedModel] = {
                    count: 1,
                    original: model,
                    current: [model]
                };
            }
        }
    });

    Object.keys(modelCount).forEach(normalizedModel => {
        const originalModel = modelCount[normalizedModel].original;
        const currentModels = modelCount[normalizedModel].current.filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined);
        let theseFilterItems = {show: false, count: '', selected: false};

        currentModels.forEach(current => {
          let thisFilterItem = setFilterItem("Model", current, "model", ModelResults);
          theseFilterItems.show = thisFilterItem.show ? true : theseFilterItems.show;
          theseFilterItems.selected = thisFilterItem.selected ? true : theseFilterItems.selected;
          let thisCount = thisFilterItem.count == '' ? 0 : parseInt(thisFilterItem.count.replace('(','').replace(')',''))
          let theseCounts = theseFilterItems.count == '' ? 0 : parseInt(theseFilterItems.count.replace('(','').replace(')',''))
          let finalCount = thisCount + theseCounts;
          theseFilterItems.count = finalCount > 0 ? '(' + finalCount.toString() + ')' : ''
        });

        filterItems.Model[originalModel] = theseFilterItems
    });

    let BodyTypeResults =   getMyResults(true,"bodytype")
    filterData.map(result => result.VehicleInfo.BodyType).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.BodyType[theKey] = setFilterItem("BodyType",theKey,"bodytype",BodyTypeResults)
    });

    let YearResults =  getMyResults(true,"year")
    filterData.map(result => result.VehicleInfo.Year).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Year[theKey] =  setFilterItem("Year",theKey,"year",YearResults)
    });

    let EngineResults =  getMyResults(true,"engine")
    filterData.map(result => result.VehicleInfo.Engine).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Engine[theKey] = setFilterItem("Engine",theKey,"engine",EngineResults)
    });

    let CityMPGResults =  getMyResults(true,"citympg")
    filterData.map(result => result.VehicleInfo.CityMPG).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.CityMPG[theKey] = setFilterItem("CityMPG",theKey,"citympg",CityMPGResults)
    });

    let HwyMPGResults =  getMyResults(true,"hwympg")
    filterData.map(result => result.VehicleInfo.HwyMPG).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.HwyMPG[theKey] = setFilterItem("HwyMPG",theKey,"hwympg",HwyMPGResults)
    });

    let TrimResults =   getMyResults(true,"trim")
    filterData.map(result => result.VehicleInfo.Trim).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Trim[theKey] = setFilterItem("Trim",theKey,"trim",TrimResults)
    });

    let BodyStyleResults =   getMyResults(true,"bodystyle")
    filterData.map(result => result.VehicleInfo.BodyStyle).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.BodyStyle[theKey] = setFilterItem("BodyStyle",theKey,"bodystyle",BodyStyleResults)
    });

    let Comments4Results =   getMyResults(true,"comments4")
    filterData.map(result => result.VehicleInfo.Comments4).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Comments4[theKey] = setFilterItem("Comments4",theKey,"comments4",Comments4Results)
    });

    let DrivetrainResults =  getMyResults(true,"drivetrain")
    filterData.map(result => result.VehicleInfo.Drivetrain).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Drivetrain[theKey] = setFilterItem("Drivetrain",theKey,"drivetrain",DrivetrainResults)
    });

    let EngineFuelTypeResults =  getMyResults(true,"fuel")
    filterData.map(result => result.VehicleInfo.EngineFuelType).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = getFuelType(key)
      filterItems.EngineFuelType[theKey] = setFilterItem("EngineFuelType",theKey,"fuel",EngineFuelTypeResults,getFuelType)
    });

    let TransmissionResults =  getMyResults(true,"transmission")
    filterData.map(result => result.VehicleInfo.Transmission).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Transmission[getTransmissionType(theKey)] = setFilterItem("Transmission",getTransmissionType(theKey),"transmission",TransmissionResults,getTransmissionType)
    });

    let InteriorColorResults =  getMyResults(true,"interiorcolor")
    filterData.map(result => result.VehicleInfo.InteriorColor).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.InteriorColor[getColor(theKey)] = setFilterItem("InteriorColor",getColor(theKey),"interiorcolor",InteriorColorResults,getColor)
    });

    let GenericExteriorColorResults =  getMyResults(true,"exteriorcolor")
    filterData.map(result => result.VehicleInfo.GenericExteriorColor).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.GenericExteriorColor[getColor(theKey)] = setFilterItem("GenericExteriorColor",getColor(theKey),"exteriorcolor",GenericExteriorColorResults,getColor)
    });
  }


  function msrpClass(amount){
    let theClass = "sr__costs"
    if(amount == 0){
      theClass = theClass + " msrp__hide"
    }
    return theClass
  }

  function insertUrlParam(key, value) {
    if (history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      if (!searchParams.has('fq', "1") && searchParams.toString() != "=" ) {
        searchParams.set('fq', "1");
      }  
     // searchParams.set("page",StateResults.page)
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      history.replaceState({path: newurl}, '', newurl);
    }
  }
  function getSearchParams(){
    let newurl = '?'
    if (history.pushState) {
      let searchParams = new URLSearchParams(pageContentSRP? pageContentSRP:window.location.search);
      newurl = '?' + searchParams.toString();
    }
    return newurl
  }

  function removeUrlParameter(paramKey) {
    const url = window.location.href
    var r = new URL(url)
    const beforeURL = r.href
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    if(beforeURL !=newUrl ){
      history.replaceState({ path: newUrl }, '', newUrl)
    }
  }
  function setSearchParams() {
    if ('URLSearchParams' in window) {
      let searchParams = new URLSearchParams(pageContentSRP? pageContentSRP:window.location.search)

      StateFilters.filter(con => { return con.value === null }).forEach(function (item, index) {
        removeUrlParameter(item.name)
      });
      StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length == 0 }).forEach(function (item, index) {
        removeUrlParameter(item.name)
      });

      StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 }).forEach(function (item, index) {
        if(!["price","cost","mileage","year","daysinstock"].includes(item.name)){
          insertUrlParam(item.name, item.value);
        }else{
          if(item.name === "daysinstock") {
            const nowDate = new Date(Date.now()); //yesterday (Date.now() - 86400000) for test
            nowDate.setUTCHours(0,0,0,0)

            const diffMs = buildDate.getTime() - nowDate.getTime()
            const diffDays = Math.floor(diffMs > 0 ? diffMs / (1000 * 3600 * 24) : 0)

            insertUrlParam("min-" + item.name, (parseInt(item.value[0]) - diffDays));
            insertUrlParam("max-" + item.name, (parseInt(item.value[1]) - diffDays));
          }
          else {
            insertUrlParam("min-" + item.name, item.value[0]);
            insertUrlParam("max-" + item.name, item.value[1]);
          }
        }
      });
      StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"}).forEach(function (item, index) {
        insertUrlParam(item.name, item.value.trim());
      });

    }

  }
  function doSearchDelay(text){
    if(timer){clearTimeout(timer)}
    let search = text.target.value
    const newTimer = setTimeout(() => {
      updateFilter("search", search)
    }, 300)
    setTimer(newTimer)
    if(text.target.value == ''){
      removeUrlParameter("search")
    }
  }

  function resetSearchPage(){
    let theParams = []
    for (const elm of params.keys()) {
      theParams = theParams.concat(elm)
    }
    //resets any filter that isn't an object, back to
    StateFilters.filter(con => { return typeof con.value !== "object" && con.value !== null }).map((f) => {
      if(!theParams.includes(f.name)){
        if(!["price","cost","mileage","year","daysinstock"].includes(f.name)){
          setFilter(f.name,f.value)
        }
      }
    })
    StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 }).map((f) => {
      if(!theParams.includes(f.name)){
        if(!["price","cost","mileage","year","daysinstock"].includes(f.name)){
          if(f.value.length > 0){
            f.value.forEach((v) => { setFilter(f.name,v) })
          }
        }
      }else{
        let theParamValue = params.getAll(f.name)
        f.value.forEach((v) => { if(!theParamValue.includes(v)) {setFilter(f.name,v) }})
      }
    })
    if(document.querySelector('.open-filters-srp-page-wrap_active') !== null){
      document.querySelector('.open-filters-srp-page-wrap_active').classList.toggle('open-filters-srp-page-wrap_active');
    }
    if(document.querySelector('.open-filters-btn__text_active') !== null){
      document.querySelector('.open-filters-btn__text_active').classList.toggle('open-filters-btn__text_active');
    }
    if(document.querySelector('.filters-wrap_visible') !== null){
      document.querySelector('.filters-wrap_visible').classList.toggle('filters-wrap_visible')
    }
    setQueryState(SearchParam)
    setResults(getParamResults(StateIndex))
  }
  useEffect(() => {
    if(pageContentSRP !== (null || undefined) && !adjustedParamCheck){
      const url = window.location.href
      history.replaceState({ path: url }, null, `?${pageContentSRP}`);
    }
    //pass parameters into contact form
    if(SearchParam === "")
      insertUrlParam('','')
    //same query not reset needed
    if(queryState === SearchParam) return
    //if the query params change reset the search page to work
    resetSearchPage()
    if(typeof window !== 'undefined')
      window.dispatchEvent(DealerMasterEvent)

    
  },[SearchParam])

  setupFilterDataResults(StateResults,true);
  setupFilterData()

  const [isOpen, toggleOpen] = useState([false, false, false,false,false,false,false])
  const toggleMenu = (idx) => {
    const _isOpen = isOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    if(idx === 3){
      document.querySelector('.more-filters').classList.toggle('more-filters_visible')
    }
    toggleOpen(_isOpen)
  }

  const [isMoreOpen, toggleMoreOpen] = useState([false, false, false,false,false])
  const toggleMoreMenu = (idx) => {
    const _isMoreOpen = isMoreOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    toggleMoreOpen(_isMoreOpen)
  }

  function toggleClass(mainClassName,toggleClassName,checkedClassName){

    /*document.querySelectorAll('.'+mainClassName+'.'+checkedClassName)
      .forEach((item) => {
        if(!item.classList.contains(toggleClassName)){
          item.classList.toggle(checkedClassName)
        }
      })*/

    document.querySelector('.' + toggleClassName).classList.toggle(checkedClassName)
  }
  const hideMenus = () => {
    const _isOpen = isOpen.map(() => (false))
    toggleOpen(_isOpen)
    //document.querySelector('.more-filters').classList.remove('more-filters_visible')
  }
  function getFilterItems(Item){
    return Object.keys(filterItems[Item]).sort().filter((r) => filterItems[Item][r].show)
  }

  function loadFirstPhoto () {
    let mainPhoto = ""
    let LunrVin = Index.load(additionalInventory ? data.AltLunrIndex.index : data.AltLunrIndex.index).search("").filter(item => {
      return allDataDict[item.ref] !== undefined
    }).sort(function(a, b) {
      let itemA = allDataDict[a.ref]
      let itemB = allDataDict[b.ref]
        return itemB.Pricing.ExtraPrice1 - itemA.Pricing.ExtraPrice1
    })[0]
    
    LunrVin = LunrVin !== undefined && LunrVin[0] !== undefined ? LunrVin[0].ref : undefined
    mainPhoto = typeof allDataDict[LunrVin] == 'undefined' ? unavailableImg: allDataDict[LunrVin].MainPhotoUrl
    if (mainPhoto === "")
    {
      mainPhoto = unavailableImg
    }
    return mainPhoto
  }

  function orderCategories() {
    let orderArray = siteSettings.inventorymanagement.filterOptions.split("")
    let options = orderArray.map((item, i) => {
      if (item === 'N') {
        return (
          <button className={ IsNew() ? 'switch-btns__btn condition-new-switch-btns__btn switch-btns__btn_active' : 'switch-btns__btn condition-new-switch-btns__btn' }
            onClick={() => { updateFilter('new','true'); toggleClass("switch-btns__btn","condition-new-switch-btns__btn","switch-btns__btn_active")}}>New</button>
        )
      }
      if (item === 'U') {
        return (
          <button className={  (IsUsed() ? "switch-btns__btn condition-used-switch-btns__btn switch-btns__btn_active" : "switch-btns__btn condition-used-switch-btns__btn ") }
            onClick={() => { updateFilter('new','false'); toggleClass("switch-btns__btn","condition-used-switch-btns__btn","switch-btns__btn_active")}}>Used</button>
        )
      }
      if (item === 'C') {
        return (
          <button className={ (IsCertified() ? "switch-btns__btn condition-certified-switch-btns__btn switch-btns__btn_active" : "switch-btns__btn condition-certified-switch-btns__btn ") } onClick={() => { updateFilter('certified','true'); toggleClass("switch-btns__btn","condition-certified-switch-btns__btn","switch-btns__btn_active")}}>Certified</button>
        )
      }
      else
        return (<></>)
    })
    return options
  }

  if(typeof window !== 'undefined')
    window.dispatchEvent(DealerMasterEvent)

  function handleShowMoreRendering() {
    if (filtersApplied) {
      if (overwriteShowMoreInventory) {
        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: overwriteShowMoreInventoryHTML }} />
            <SearchRecommended props={{ additionalInventory,
              retailPriceCustomText,retailPriceDiscountCustomText,dealerPriceCustomText,
                fallbackText, siteSettings, "StateResults": StateAllResults, setFavorites, theSlug, useCompactCards,
              SRPCardBtns, msrpClass, formatPrice, allDataDict, unavailableImg, AutoCheckEnabled,
              lead_url, site_id,gtmId, sortTheItems, disclaimerCalculator, disclaimerContact,
              disclaimerFinanceApp, defaultMonth, downpayment, rates,DealerMasterEvent,ShowLocationFilter,HideNewUsed, filterState, strikeMSRP, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers
            }}/>
          </>
        )
      }

      if (showMoreInventory) {
        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: showMoreInventoryHTML }} />
            <SearchRecommended props={{ additionalInventory,
              retailPriceCustomText,retailPriceDiscountCustomText,dealerPriceCustomText,
                fallbackText, siteSettings, "StateResults": StateAllResults, setFavorites, theSlug, useCompactCards,
              SRPCardBtns, msrpClass, formatPrice, allDataDict, unavailableImg, AutoCheckEnabled,
              lead_url, site_id,gtmId, sortTheItems, disclaimerCalculator, disclaimerContact,
              disclaimerFinanceApp, defaultMonth, downpayment, rates,DealerMasterEvent,ShowLocationFilter,HideNewUsed, filterState, strikeMSRP, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers
            }}/>
          </>
        )
      }
    }
  }

  return (
    <Layout seo={pageSeo} footerClassName={"srp-footer"}>
    <link rel="preload" as="image" href={loadFirstPhoto()} />
	  <div className="srp-page-wrap"  style={{ padding: "20px"}}>
      {/* CONDITIONAL HERE */}
      {pageContext?.pageContent?.SRP?.disableTopHtml?
        null
        :
        <InnerHTML html={topHtmlSRP} />
      }
      <InnerHTML html={staticTopHtmlSrp} />
      { screenWidth > 768 &&
              <button className="open-filters-btn" onClick={() => { 
                document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  
                document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  
                document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible')
                }
                }>
                <span className="open-filters-btn__text">
                  <span className="open-it">Open </span> 
                  <span className="close">Close </span>  
                  Filters
                </span>
              </button>
        }
        { screenWidth <= 768 &&
              <>
              <button className="open-filters-btn" onClick={() => { 
                document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  
                document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  
                document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible')
                }
                }>
                <span className="open-filters-btn__text">
                  <span className="open-it">Open </span> 
                  <span className="close">Close </span>  
                  Filters
                </span>
                <span>
                  <p className="num-of-vehicles" style={{fontSize: "12px", lineHeight: "16px"}}>
                    <span id="results" className="num-of-vehicles__number">{StateResults.length}</span> vehicles found
                  </p>
                </span>
              </button>
              </>
        }
        <div className="content-wrap">
          <div className="sidebar" style={{"z-index": "3"}}>
            {/*<form className="sidebar-btns sidebar-btns-reset">
              <button className="srp-search__reset-btn">Reset Filters</button>
            </form>*/}
            <div className="sidebar__section-01">
              { !additionalInventory && !searchParamsHasNew()
              ? <div className="switch-btns">
                  { orderCategories() }
                </div>
              : <></>
              }
              
              {ShowLocationFilter && <div className={"options-list-v2 dealer "+ (getFilterItems("DealerName").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.dealer.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Location: <span className="options-list-v2__selected-value">{getFilterLabel('dealer','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("DealerName").map(Dealer => {
                    let toggleClassName = "dealer-" + Dealer.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.DealerName[Dealer].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('dealer', Dealer.toString().split(" ").join(""),Dealer); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Dealer} {filterItems.DealerName[Dealer].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>}
              <div className="options-list-v2 year enabled">
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.year.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Year: <span className="options-list-v2__selected-value" >{getFilterLabel('year', 'Year') == "Year" ?  store.year[0] + " - " + store.year[store.year.length - 1] : getFilterLabel('year', 'Year') }</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                  <Slider
                      value={sliderYearValue}
                      onChange={yearChange}
                      onChangeCommitted={yearCommitted}
                      // valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      steps={1}
                      min={store.year[0]}
                      max={store.year[store.year.length - 1]}
                  />
                </div>
              </div>
              {searchParamsHasNew() ? null :
              <div className={"options-list-v2 make "+ (getFilterItems("Make").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.make.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Make: <span className="options-list-v2__selected-value">{getFilterLabel('make','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Make").map(Make => {
                    let toggleClassName = "make-" + Make.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Make[Make].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('make', Make.toString().split(" ").join(""),Make); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Make} {filterItems.Make[Make].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              }
              <div className={"options-list-v2 model "+ (getFilterItems("Model").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.model.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Model: <span className="options-list-v2__selected-value">{getFilterLabel('model','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Model").map(Model => {
                    let toggleClassName = "model-" + Model.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Model[Model].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('model', Model.toString().split(" ").join(""),Model); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Model} {filterItems.Model[Model].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 trim "+ (getFilterItems("Trim").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.trim.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Trim: <span className="options-list-v2__selected-value">{getFilterLabel('trim','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Trim").map(Trim => {
                    let toggleClassName = "trim-" + Trim.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Trim[Trim].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('trim', Trim.toString().split(" ").join(""),Trim); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Trim} {filterItems.Trim[Trim].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div  className={"options-list-v2 inStock enabled vehicle-status-filter"}  style={{display: "none"}}> {/* CHANGE BACK TO NONE FOR RELEASE*/}
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.inStock.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>In Stock: </span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                  <div className="options-list-v2__item-container options-list-v2__item-container_flex" >
                        <label className={"options-list-v2__item" + " " + 'stock' + " "   } > <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => { updateFilter('status','Stock'); toggleClass("options-list-v2__item", "stock","options-list-v2__item_checked") }} />
                        <span className="options-list-v2__text">In-Stock {/*filterItems.Stock[Trim].count*/} </span></label>
                        <label className={"options-list-v2__item" + " " + 'inTransit'  + " "  } > <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => { updateFilter('status','Transit'); toggleClass("options-list-v2__item", "inTransit","options-list-v2__item_checked" ) }} />
                        <span className="options-list-v2__text">In-Transit{/*filterItems.Stock[Trim].count*/} </span></label>
                  </div>
              </div>
              
              <div className={"options-list-v2 bodystyle "+ (getFilterItems("BodyStyle").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.bodystyle.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Body Style: <span className="options-list-v2__selected-value">{getFilterLabel('bodystyle','')}</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("BodyStyle").map(BodyStyle => {
                    let toggleClassName = "bodystyle_" + BodyStyle.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "bodystyle.options-list-v2__item"
                    return (
                      <label className={"bodystyle options-list-v2__item options-list-v2__item_bodystyle " + toggleClassName + (filterItems.BodyStyle[BodyStyle].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('bodystyle', BodyStyle.toString().split(" ").join(""),BodyStyle); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{BodyStyle} {filterItems.BodyStyle[BodyStyle].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 comments4 "+ (getFilterItems("Comments4").length === 0 ?"disable": "enabled") }   style={{display: "none"}}>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.comments4.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Certification Program: <span className="options-list-v2__selected-value">{getFilterLabel('comments4','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Comments4").map(Comments4 => {
                    let toggleClassName = "comments4-" + Comments4.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Comments4[Comments4].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('comments4', Comments4.toString().split(" ").join(""),Comments4); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Comments4} {filterItems.Comments4[Comments4].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 exterior "+ (getFilterItems("GenericExteriorColor").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.exterior.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Exterior Color: <span className="options-list-v2__selected-value">{getFilterLabel('exteriorcolor','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>

                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("GenericExteriorColor").map(GenericExteriorColor => {
                    let toggleClassName = "color_" + GenericExteriorColor.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "exterior.options-list-v2__item"
                    return (
                      <label className={"exterior options-list-v2__item options-list-v2__item_color " + toggleClassName + (filterItems.GenericExteriorColor[GenericExteriorColor].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', GenericExteriorColor,GenericExteriorColor); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{GenericExteriorColor} {filterItems.GenericExteriorColor[GenericExteriorColor].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 interior "+ (getFilterItems("InteriorColor").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.interior.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Interior Color: <span className="options-list-v2__selected-value">{getFilterLabel('interiorcolor','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("InteriorColor").map(InteriorColor => {
                    let toggleClassName = "color_" + InteriorColor.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "interior.options-list-v2__item"
                    return (
                      <label className={"interior options-list-v2__item options-list-v2__item_color " + toggleClassName + (filterItems.InteriorColor[InteriorColor].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('interiorcolor', InteriorColor,InteriorColor); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{InteriorColor} {filterItems.InteriorColor[InteriorColor].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
              { !additionalInventory ? <div className="options-list-v2 price enabled">
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.price.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Price: <span className="options-list-v2__selected-value" >{getFilterLabel('cost', '0') === "Price" ? "$" + "0.00 - $" + numberWithCommas(store.price[store.price.length - 1]) + ".00+"  : getFilterLabel('cost', '0')}</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                  <Slider
                      value={sliderCostValue}
                      onChange={costChange}
                      onChangeCommitted={costCommitted}
                      // valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      steps={CostRange}
                      min={0}
                      max={store.price[store.price.length - 1]}
                  />
                </div>
              </div>
              : <></>
              }
              { !additionalInventory ? <div className="options-list-v2 mileage enabled">
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.mileage.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Mileage: <span className="options-list-v2__selected-value" >{getFilterLabel('mileage', '0')}</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                  <Slider
                      value={sliderMileageValue}
                      onChange={mileageChange}
                      onChangeCommitted={mileageCommitted}
                      // valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      steps={MileageRange}
                      min={0}
                      max={store.mileage[store.mileage.length - 1]}
                  />
                </div>
              </div>
              : 
              <></>
              }
              <div className={"options-list-v2 drivetrain "+ (getFilterItems("Drivetrain").length === 0 ?"disable": "enabled") }>
                 <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.drivetrain.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Drive Type: <span className="options-list-v2__selected-value">{getFilterLabel('drivetrain','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Drivetrain").map(Drivetrain => {
                    let toggleClassName = "drivetrain-" + Drivetrain.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "drivetrain.options-list-v2__item"
                    return (
                      <label className={"drivetrain options-list-v2__item " + toggleClassName + (filterItems.Drivetrain[Drivetrain].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('drivetrain', Drivetrain.toString().split(" ").join(""),Drivetrain); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Drivetrain} {filterItems.Drivetrain[Drivetrain].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 transmission "+ (getFilterItems("Transmission").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.transmission.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Transmission: <span className="options-list-v2__selected-value">{getFilterLabel('transmission','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Transmission").map(Transmission => {
                    let toggleClassName = "transmission-" + Transmission.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "transmission.options-list-v2__item"
                    return (
                      <label className={"transmission options-list-v2__item " + toggleClassName + (filterItems.Transmission[Transmission].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('transmission', Transmission.toString().split(" ").join(""),Transmission); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Transmission} {filterItems.Transmission[Transmission].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 fuel "+ (getFilterItems("EngineFuelType").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.fuel.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Fuel: <span className="options-list-v2__selected-value">{getFilterLabel('fuel','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("EngineFuelType").map(EngineFuelType => {
                    let toggleClassName = "fuel-" + EngineFuelType.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "fuel.options-list-v2__item"
                    return (
                      <label className={"fuel options-list-v2__item " + toggleClassName + (filterItems.EngineFuelType[EngineFuelType].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('fuel', EngineFuelType.toString().split(" ").join(""),EngineFuelType); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{EngineFuelType} {filterItems.EngineFuelType[EngineFuelType].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="sidebar-mobile-btns-reset">
              {/* <Link to={(typeof window !== 'undefined' ? window.location.href : "/search/?")} className="switch-btns__btn srp-search__clear-results" >Clear</Link> */}
              <form className="srp-search__clear-results">
                <button className="mobile_clear_btn" type="submit" >Clear</button>
              </form>
              <button className="switch-btns__btn srp-search__see-results"
                      onClick={() => {window.scrollTo(0,0);document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible')}}>
                See {StateResults.length} Results </button>
            </div>
          </div>

          <div className="sr-wrap">
              <div className="srp-row srp-search">
              <form className="srp-search__search-form">
                <label hidden htmlFor="srp-search-input">Search</label>
                {/* <input id="srp-search-input" className="srp-search__search-input" type="search" onChange={text => updateFilter("search", text.target.value)} placeholder="Type Here" /> */}
                <input className="srp-search__search-input"  style={{fontSize: "16px"}} type="search"
                      onChange={function(e){doSearchDelay(e)}}
                      onKeyPress={function(e) {
                        var KeyID = e.which;
                        switch(KeyID)
                        {
                          case 13:
                            e.preventDefault();
                            break;
                        }
                      }}
                      onKeyDown={function(e) {
                        var KeyID = e.which;
                        switch(KeyID)
                        {
                          case 8:
                            if(e.target.value.trim() == ''){
                              updateFilter("search", e.target.value.trim())
                              removeUrlParameter("search")
                              break;
                            }
                            if(!SearchValueCheck(e.target.value)){
                              updateFilter("search", e.target.value)
                            }
                            break;
                        }
                      }}
                      placeholder={'Type Here'}
                        defaultValue={
                          (StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"}).length > 0) ?
                              StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"})[0].value :
                              SearchTextBox !== null ? SearchTextBox : ''}  />

                { screenWidth > 768 && <button className="srp-search__reset-btn">Reset Filters</button> }
              </form>
              { screenWidth > 768 ? <></> : <div className="mobile-sort-by-container">{renderSortOptions()}</div>}
            </div>
            <div className="srp-row srp-row-top">
              <h1 className="inventory-type-title">
                {getHeaderLabel()} {inventoryTextHeader}
              </h1>
            </div>
            <div className="srp-row">
                { screenWidth > 768 &&
                  <p className="num-of-vehicles">
                    <span id="results" className="num-of-vehicles__number">{StateResults.length}</span> vehicles found
                  </p>
                }
              {screenWidth > 768 && <a href="?fav=true" aria-label="Favorites" className="favorites-link">Favorites (<span className="favorites-link__num">{StateFavorites.length}</span>)</a>}
              {(screenWidth <= 768 && StateFavorites.length > 0) && <a href="?fav=true" aria-label="Favorites" className="favorites-link">Favorites (<span className="favorites-link__num">{StateFavorites.length}</span>)</a>}

              { screenWidth > 768 &&
              <div>{renderSortOptions()}</div>
              }


            </div>
            <div className={StateResults.length === 0 ? "num-of-vehicles sr__no_results" :"sr__has_results" }>  
              <p class={"no_match_class"}>No matches were found.</p>
              <br/>
              Please try <a href="/search/?" >clearing your filters</a> <span class={"srp-clear-filter-nuc"} >or searching in <a href="/search/?new=true">New</a>, <a href="/search/?new=false&certified=true">Used</a>, or <a href="/search/?certified=true">Certified</a> inventory.</span>
              </div>
            <FinanceCalculatorProvider>
              <SearchRecommended props={{ additionalInventory,
                retailPriceCustomText,retailPriceDiscountCustomText,dealerPriceCustomText,
                 fallbackText, siteSettings,StateResults, setFavorites, theSlug, useCompactCards,
                SRPCardBtns, msrpClass, formatPrice, allDataDict, unavailableImg, AutoCheckEnabled,
                lead_url, site_id,gtmId, sortTheItems, disclaimerCalculator, disclaimerContact,
                disclaimerFinanceApp, defaultMonth, downpayment, rates,DealerMasterEvent,ShowLocationFilter,HideNewUsed, filterState, strikeMSRP, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers
              }}/>

              {handleShowMoreRendering()}
              
            </FinanceCalculatorProvider>
          </div>
        </div>
        {pageContext?.pageContent?.SRP?.disableBottomHtml?
          null
          :
          <div dangerouslySetInnerHTML={{ __html: botHtmlSRP }} />
        }
        <div dangerouslySetInnerHTML={{ __html: staticBottomHtmlSRP }} />
        <div className="sr-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <data id="page" value="Search Results Page"></data>
      </div>
    </Layout >
  )}

  export default SearchPage

export const pageQuery = graphql`
query (
  $DealerID: String
) {
  site {
    siteMetadata {
      site_id
      lead_url
    }
  }
  AltLunrIndex(dealerId: $DealerID)
  siteData {
   siteData {
      site_id
      siteSettings
      siteMetadata {
        currentTemplateID
        siteName
      }
      buildDate
    }
  }
  AllInventory(dealerId: $DealerID) {
        DealerID
        VIN
        Pricing {
          Cost
          ExtraPrice1
          ExtraPrice2
          ExtraPrice3
          List
          Special
        }
        ListOfAppliedOffers {
          Amount
          OfferDescription
          OfferEndDate
          OfferName
          Price
        }
        Banner {
          BannerContent {
            bgColor
            placement
            text
            textColor
          }
        }
        VehicleInfo {
          IsCertified
          IsNew
          Model
          Make
          Year
          VIN
          BodyType
          StockNumber
          DealerName
          Trim
          VehicleStatus
          Mileage
          Drivetrain
          EngineFuelType
          InteriorColor
          GenericExteriorColor
          BodyStyle
          Transmission
          InStockDate
          DealerID
          Comments4
          PhotoCount
        }
        MainPhotoUrl
      
    
  }
  }`
